import { combineReducers } from 'redux';
import observationsReducer from './observationsReducer';
import userReducer from './userReducer';
import permissionsReducer from './permissionsReducer';
import { mobileCamerasReducer } from '../components/scenes/Cop/Settings/containers/MobileCameras/slice';
import { fixedCamerasReducer } from '../components/scenes/Cop/Settings/containers/FixedCameras/slice';
import { exemptionTypesReducer } from '../components/scenes/Cop/Settings/containers/Exemptions/components/ExemptionTypes/slice';
import { exemptionsListReducer } from '../components/scenes/Cop/Settings/containers/Exemptions/components/ExemptionsList/slice';
import { exemptionDetailsReducer } from '../components/scenes/Cop/Settings/containers/Exemptions/components/ExemptionDetails/slice';
import { exemptionRequestsReducer } from '../components/scenes/Cop/Suspects/containers/ExemptionRequests/slice';
import { useCasesReducer } from '../components/scenes/Cop/Settings/containers/UseCases/slice';
import { powerBIPageReducer } from '../components/scenes/PowerBIPage/slice';
import { locationsReducer } from '../components/scenes/Cop/Settings/containers/FixedCameras/locationsSlice';
import { useCaseListsReducer } from '../components/scenes/Cop/Settings/containers/UseCaseLists/slice';
import { eventsReducer } from '../components/scenes/Cop/AccountSettings/containers/Events/slice';

const rootReducer = combineReducers({
  observations: observationsReducer,
  user: userReducer,
  permissions: permissionsReducer,
  mobileCameras: mobileCamerasReducer,
  fixedCameras: fixedCamerasReducer,
  locations: locationsReducer,
  exemptionTypes: exemptionTypesReducer,
  exemptionsList: exemptionsListReducer,
  exemptionRequests: exemptionRequestsReducer,
  useCases: useCasesReducer,
  powerBIPage: powerBIPageReducer,
  exemptionDetails: exemptionDetailsReducer,
  useCaseLists: useCaseListsReducer,
  events: eventsReducer
});

export default rootReducer;
