import { gql } from 'apollo-boost';

export const GET_EXEMPTION_TYPES = gql`
  query GetExemptionTypes($nonCopSettingsScope: Boolean = false) {
    exemptionTypes(search: { nonCopSettingsScope: $nonCopSettingsScope }) {
      id
      name
      global
    }
  }
`;
