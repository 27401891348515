import { gql } from 'apollo-boost';

const EXEMPTION_TYPES_QUERY = gql`
  query GetExemptionTypes($search: ExemptionTypeSearch) {
    exemptionTypes(search: $search) {
      id
      name
      global
      isDeletable
    }
  }
`;

const EXEMPTION_TYPE_DETAILS_QUERY = gql`
  query GetExemptionTypeDetails($exemptionTypeId: [ID!]) {
    exemptionTypes(search: { id: $exemptionTypeId }) {
      id
      name
      global
    }
  }
`;

export { EXEMPTION_TYPES_QUERY, EXEMPTION_TYPE_DETAILS_QUERY };
