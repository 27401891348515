import { gql } from 'apollo-boost';

export const GET_EXEMPTION_REQUESTS = gql`
  query GetExemptionRequests {
    exemptionRequests {
      id
      createdAt
      useCaseResult {
        id
        vehicleLicensePlateNumber
        useCase {
          id
          name
        }
      }
      exemptionType {
        id
        name
        global
      }
      requestedBy {
        id
        name
      }
    }
  }
`;

export const GET_EXEMPTION_REQUEST = gql`
  query GetExemptionRequest($search: ExemptionRequestSearch) {
    exemptionRequests(search: $search) {
      id
      comment
      useCaseResult {
        id
        vehicleLicensePlateNumber
        countryCode
        files {
          url
          kind
        }
        panoramaFiles {
          url
          kind
        }
        videoFiles {
          url
          kind
        }
        observation {
          id
          railwayObservation
        }
      }
      exemptionType {
        id
        name
        global
      }
    }
  }
`;
