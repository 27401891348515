import ApolloClient from 'apollo-boost';
import gql from 'graphql-tag';

/*
 * Method to retrieve the csrf-token form the page
 *
 * @returns {String} - Application token or null.
 */

const authenticityToken = () => {
  const token = document.querySelector('meta[name="csrf-token"]');
  if (token && token instanceof window.HTMLMetaElement) {
    return token.content;
  }
  return null;
};

const Client = new ApolloClient({
  uri: '/api/v2/graphql',
  headers: {
    'X-CSRF-Token': authenticityToken()
  },
  typeDefs: gql`
    enum ObservationTicketTypes {
      TICKET
      WARNING
      DELETE
    }
    enum SuspectIdentificationTypes {
      PASSPORT
      DRIVING_LICENSE
      ID_CARD
      ID_FOREIGNER
      OTHER
    }
    enum SuspectGenderTypes {
      MALE
      FEMALE
    }
  `
});

export default Client;
