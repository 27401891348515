import { gql } from 'apollo-boost';

const UPDATE_USE_CASE_MUTATION = gql`
  mutation UpdateUseCase(
    $id: ID!
    $name: String
    $description: String
    $ttlInHours: Int
    $ttlForExemptInHours: Int
    $ttlForUndoInHours: Int
    $allowValidationWithMissingVehicleType: Boolean
    $dismissalReasonRequired: Boolean
    $defaultOffense: Boolean
    $offenseCode: String
    $awayStatement: String
    $towardStatement: String
    $defaultStatement: String
    $warningEnabled: Boolean
    $exemptionEnabled: Boolean
    $cameras: [Int!]
    $usesByMobile: Boolean
  ) {
    useCaseUpdate(
      id: $id
      name: $name
      description: $description
      ttlInHours: $ttlInHours
      ttlForExemptInHours: $ttlForExemptInHours
      ttlForUndoInHours: $ttlForUndoInHours
      allowValidationWithMissingVehicleType: $allowValidationWithMissingVehicleType
      dismissalReasonRequired: $dismissalReasonRequired
      defaultOffense: $defaultOffense
      offenseCode: $offenseCode
      awayStatement: $awayStatement
      towardStatement: $towardStatement
      defaultStatement: $defaultStatement
      warningEnabled: $warningEnabled
      exemptionEnabled: $exemptionEnabled
      cameras: $cameras
      usesByMobile: $usesByMobile
    ) {
      id
      name
      description
      uuid
      ttlInHours
      ttlForExemptInHours
      ttlForUndoInHours
      allowValidationWithMissingVehicleType
      dismissalReasonRequired
      defaultOffense
      offenseCode
      awayStatement
      towardStatement
      defaultStatement
      warningEnabled
      exemptionEnabled
      usesByMobile
      cameras {
        id
        name
        type
        meta(useCaseId: [$id])
        zoneId(useCaseId: [$id])
      }
      pipelines {
        id
        name
        minutesAfterObservation
        scheduleType
        specifiedTime
        pipelineProcessors {
          id
          seqNum
          className
          config
          name
        }
      }
    }
  }
`;

const CREATE_USE_CASE_MUTATION = gql`
  mutation CreateUseCase(
    $name: String
    $description: String
    $ttlInHours: Int
    $ttlForExemptInHours: Int
    $ttlForUndoInHours: Int
    $allowValidationWithMissingVehicleType: Boolean
    $dismissalReasonRequired: Boolean
    $defaultOffense: Boolean
    $offenseCode: String
    $awayStatement: String
    $towardStatement: String
    $defaultStatement: String
    $warningEnabled: Boolean
    $exemptionEnabled: Boolean
  ) {
    useCaseCreate(
      name: $name
      description: $description
      ttlInHours: $ttlInHours
      ttlForExemptInHours: $ttlForExemptInHours
      ttlForUndoInHours: $ttlForUndoInHours
      allowValidationWithMissingVehicleType: $allowValidationWithMissingVehicleType
      dismissalReasonRequired: $dismissalReasonRequired
      defaultOffense: $defaultOffense
      offenseCode: $offenseCode
      awayStatement: $awayStatement
      towardStatement: $towardStatement
      defaultStatement: $defaultStatement
      warningEnabled: $warningEnabled
      exemptionEnabled: $exemptionEnabled
    ) {
      id
      name
      description
      uuid
      ttlInHours
      ttlForExemptInHours
      ttlForUndoInHours
      allowValidationWithMissingVehicleType
      dismissalReasonRequired
      defaultOffense
      offenseCode
      awayStatement
      towardStatement
      defaultStatement
      warningEnabled
      exemptionEnabled
    }
  }
`;

const DELETE_USE_CASE_MUTATION = gql`
  mutation DeleteUseCase($id: ID!) {
    useCaseDelete(id: $id) {
      id
    }
  }
`;

const CREATE_PIPELINE_MUTATION = gql`
  mutation pipelineCreate(
    $name: String
    $scheduleType: String
    $specifiedTime: String
    $minutesAfterObservation: Int
    $useCaseId: Int
  ) {
    pipelineCreate(
      name: $name
      scheduleType: $scheduleType
      specifiedTime: $specifiedTime
      minutesAfterObservation: $minutesAfterObservation
      useCaseId: $useCaseId
    ) {
      id
      name
      scheduleType
      specifiedTime
      minutesAfterObservation
    }
  }
`;

const UPDATE_PIPELINE_MUTATION = gql`
  mutation UpdatePipeline(
    $id: ID!
    $name: String
    $scheduleType: String
    $specifiedTime: String
    $minutesAfterObservation: Int
  ) {
    pipelineUpdate(
      id: $id
      name: $name
      scheduleType: $scheduleType
      specifiedTime: $specifiedTime
      minutesAfterObservation: $minutesAfterObservation
    ) {
      id
      name
      scheduleType
      specifiedTime
      minutesAfterObservation
    }
  }
`;

const DELETE_PIPELINE_MUTATION = gql`
  mutation DeletePipeline($id: ID!) {
    pipelineDelete(id: $id) {
      id
    }
  }
`;

const UPDATE_PIPELINE_PROCESSOR_MUTATION = gql`
  mutation UpdatePipelineProcessor($id: ID!, $seqNum: Int, $config: JSON, $name: String!) {
    pipelineProcessorUpdate(id: $id, seqNum: $seqNum, config: $config, name: $name) {
      id
      className
      seqNum
      config
      name
    }
  }
`;

const CREATE_PIPELINE_PROCESSOR_MUTATION = gql`
  mutation CreatePipelineProcessor(
    $className: String
    $config: JSON
    $copPipelineId: ID
    $name: String!
  ) {
    pipelineProcessorCreate(
      className: $className
      config: $config
      copPipelineId: $copPipelineId
      name: $name
    ) {
      id
      className
      seqNum
      config
      name
    }
  }
`;

const UPDATE_SEQNUM_PIPELINE_PROCESSORS_MUTATION = gql`
  mutation UpdateSeqnumPipelineProcessors($order: [Int!]) {
    pipelineProcessorUpdateSeqnum(order: $order) {
      count
    }
  }
`;

const DELETE_PIPELINE_PROCESSOR_MUTATION = gql`
  mutation DeletePipelineProcessor($pipelineProcessorId: ID!) {
    pipelineProcessorDelete(id: $pipelineProcessorId) {
      id
    }
  }
`;

const CREATE_USE_CASE_ZONE_MUTATION = gql`
  mutation CreateUseCaseZone($useCaseId: [ID!], $camera: Int, $type: String, $cameras: [Int!]) {
    useCaseZoneCreate(useCaseId: $useCaseId, camera: $camera, type: $type, cameras: $cameras) {
      id
      name
      description
      uuid
      ttlInHours
      ttlForExemptInHours
      ttlForUndoInHours
      dismissalReasonRequired
      defaultOffense
      offenseCode
      awayStatement
      towardStatement
      defaultStatement
      warningEnabled
      exemptionEnabled
      cameras {
        id
        name
        type
        zoneId(useCaseId: $useCaseId)
        meta(useCaseId: $useCaseId)
        useCaseCameraEnabled(useCaseId: $useCaseId)
      }
      pipelines {
        id
        name
        minutesAfterObservation
        scheduleType
        specifiedTime
        pipelineProcessors {
          id
          seqNum
          className
          config
          name
        }
      }
    }
  }
`;

const UPDATE_USE_CASE_ZONE_MUTATION = gql`
  mutation UpdateUseCaseZone(
    $id: ID!
    $roofZoneId: String
    $zoneGroupId: String
    $parkingCode: String
    $useCaseCameraEnabled: Boolean
    $useCaseId: [ID!]
  ) {
    useCaseZoneUpdate(
      id: $id
      roofZoneId: $roofZoneId
      zoneGroupId: $zoneGroupId
      parkingCode: $parkingCode
      useCaseCameraEnabled: $useCaseCameraEnabled
      useCaseId: $useCaseId
    ) {
      id
      name
      description
      uuid
      ttlInHours
      ttlForExemptInHours
      ttlForUndoInHours
      dismissalReasonRequired
      defaultOffense
      offenseCode
      awayStatement
      towardStatement
      defaultStatement
      warningEnabled
      exemptionEnabled
      cameras {
        id
        name
        type
        zoneId(useCaseId: $useCaseId)
        meta(useCaseId: $useCaseId)
        useCaseCameraEnabled(useCaseId: $useCaseId)
      }
      pipelines {
        id
        name
        minutesAfterObservation
        scheduleType
        specifiedTime
        pipelineProcessors {
          id
          seqNum
          className
          config
          name
        }
      }
    }
  }
`;

const UPDATE_USE_CASE_CAMERA_MUTATION = gql`
  mutation UpdateUseCaseCamera($id: ID!, $useCaseCameraEnabled: Boolean, $useCaseId: ID!) {
    useCaseCameraUpdate(
      id: $id
      useCaseCameraEnabled: $useCaseCameraEnabled
      useCaseId: $useCaseId
    ) {
      id
      name
      description
      uuid
      ttlInHours
      ttlForExemptInHours
      ttlForUndoInHours
      dismissalReasonRequired
      defaultOffense
      offenseCode
      awayStatement
      towardStatement
      defaultStatement
      warningEnabled
      exemptionEnabled
      cameras {
        id
        name
        type
        zoneId(useCaseId: [$useCaseId])
        meta(useCaseId: [$useCaseId])
        useCaseCameraEnabled(useCaseId: [$useCaseId])
      }
      pipelines {
        id
        name
        minutesAfterObservation
        scheduleType
        specifiedTime
        pipelineProcessors {
          id
          seqNum
          className
          config
          name
        }
      }
    }
  }
`;

const DELETE_USE_CASE_ZONE_MUTATION = gql`
  mutation DeleteUseCaseZone($useCaseId: [ID!], $camera: Int, $type: String, $cameras: [Int!]) {
    useCaseZoneDelete(useCaseId: $useCaseId, camera: $camera, type: $type, cameras: $cameras) {
      id
      name
      description
      uuid
      ttlInHours
      ttlForExemptInHours
      ttlForUndoInHours
      dismissalReasonRequired
      defaultOffense
      offenseCode
      awayStatement
      towardStatement
      defaultStatement
      warningEnabled
      exemptionEnabled
      cameras {
        id
        name
        type
        zoneId(useCaseId: $useCaseId)
        meta(useCaseId: $useCaseId)
        useCaseCameraEnabled(useCaseId: $useCaseId)
      }
      pipelines {
        id
        name
        minutesAfterObservation
        scheduleType
        specifiedTime
        pipelineProcessors {
          id
          seqNum
          className
          config
          name
        }
      }
    }
  }
`;

const CREATE_PRINT_TEMPLATE_MUTATION = gql`
  mutation printTemplateCreate($name: String, $body: String, $kind: String, $useCaseId: Int) {
    printTemplateCreate(name: $name, body: $body, kind: $kind, useCaseId: $useCaseId) {
      id
      name
      body
      kind
    }
  }
`;

const UPDATE_PRINT_TEMPLATE_MUTATION = gql`
  mutation printTemplateUpdate($id: ID!, $name: String, $body: String, $kind: String) {
    printTemplateUpdate(id: $id, name: $name, body: $body, kind: $kind) {
      id
      name
      body
      kind
    }
  }
`;

const DELETE_PRINT_TEMPLATE_MUTATION = gql`
  mutation DeletePrintTemplate($id: ID!) {
    printTemplateDelete(id: $id) {
      id
    }
  }
`;

export {
  UPDATE_USE_CASE_MUTATION,
  CREATE_USE_CASE_MUTATION,
  DELETE_USE_CASE_MUTATION,
  CREATE_PIPELINE_MUTATION,
  UPDATE_PIPELINE_MUTATION,
  DELETE_PIPELINE_MUTATION,
  CREATE_PIPELINE_PROCESSOR_MUTATION,
  UPDATE_PIPELINE_PROCESSOR_MUTATION,
  DELETE_PIPELINE_PROCESSOR_MUTATION,
  UPDATE_SEQNUM_PIPELINE_PROCESSORS_MUTATION,
  CREATE_USE_CASE_ZONE_MUTATION,
  UPDATE_USE_CASE_ZONE_MUTATION,
  DELETE_USE_CASE_ZONE_MUTATION,
  UPDATE_USE_CASE_CAMERA_MUTATION,
  CREATE_PRINT_TEMPLATE_MUTATION,
  UPDATE_PRINT_TEMPLATE_MUTATION,
  DELETE_PRINT_TEMPLATE_MUTATION
};
